import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'

import Context from 'components/common/Context'
import Layout from 'components/common/Layout'
import { checkAuthToken, clearAuthToken } from 'helpers/auth'

export default () => {
    const { user, dispatchUserAction } = useContext(Context)

    const logout = () => {
        const loggedOut = clearAuthToken(dispatchUserAction)
        if (!loggedOut) return;
        return navigate('/login');
    }

    const checkLogin = async () => {
        const loggedIn = await checkAuthToken(dispatchUserAction)
        if (!loggedIn) {
            return navigate('/login');
        }
    }

    const getFullName = (data) => {
        console.log('>> getFullName')
        let _common_data = data.profile.common_data;
        return _common_data.first_name + ' ' +
            _common_data.last_name + ' ' +
            _common_data.middle_name;
    }

    const hasUserData = () => {
        return user && user.data && user.data.profile;
    }

    useEffect(checkLogin, [])

    const name = hasUserData() ? getFullName(user.data) : "stranger"

    return (
        <Layout>
            <div>Hello, {name}!</div>
            <hr />
            <button onClick={logout}>Logout</button>
        </Layout>
    )
}


